<template>
  <navbar v-model="showNavbar" position="relative">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button id="minimizeSidebar" class="btn btn-icon btn-round" @click="minimizeSidebar">
          <i class="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"></i>
          <i class="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"></i>
        </button>
      </div>
      <div class="navbar-toggle">
        <navbar-toggle-button @click.native="toggleSidebar"></navbar-toggle-button>
      </div>
      <span class="navbar-brand">{{envTenantName}} Dashboard</span>
    </div>

    <template slot="navbar-menu">
      <div class="gold-price-section">
        <span class="gold-price-button">
          <el-tooltip placement="bottom" content="Update kurs emas terkini">
            <i class="fa fa-refresh text-muted icon-refresh" @click="fetchGoldPrice(true)"></i>
          </el-tooltip> 
          <i class="fa fa-bandcamp text-warning icon-normal"></i>
        </span>
        <el-tooltip placement="bottom" :content="goldPrice.formattedPostedDate ? 'Kurs emas tanggal ' + goldPrice.formattedPostedDate : 'Data tidak tersedia'">
          <span> {{goldPrice.formattedPrice || '-'}}</span>
        </el-tooltip>
      </div>
    </template>

    <template slot="navbar-notification" v-if="isGoldPriceExpired">
      <div class="kf container-fluid">
        <div class="alert alert-danger alert-with-icon fade show mb-0" data-notify="container">
          <span data-notify="icon" class="fa fa-bandcamp"></span>
          <span data-notify="message">
            Kurs emas hari ini belum diupdate. 
            <span v-if="canUpdateGoldPrice">Klik <router-link :to="goldPriceDetailRoute">disini</router-link> untuk mengupdate kurs emas.</span>
          </span>
        </div>
      </div>
    </template>
  </navbar>
</template>
<script>
  import moment from 'moment'
  import { Tooltip } from 'element-ui'
  import { Navbar, NavbarToggleButton } from 'src/components/UIComponents'
  import filterHelper from '../../../helpers/filterHelper'
  import { GOLD_PRICE_FETCH } from '../../../store/actions/goldPrice'
  import errorHandlerHelper from '../../../helpers/errorHandlerHelper'
  import * as urlConstant from '../../../constants/urlConstant'
  import * as userRoleEnum from '../../../enums/userRoleEnum'
  import alertHelper from '../../../helpers/alertHelper'

  export default {
    components: {
      Navbar,
      NavbarToggleButton,
      [Tooltip.name]: Tooltip,
    },
    data() {
      return {
        showNavbar: false,
        goldPriceDetailRoute: urlConstant.WEB_URL_REFERENCES_GOLD_PRICES_NEW,
        intervals: {
          goldPriceFetcher: null
        }
      }
    },
    created () {
      // Fetch gold every 30 minutes
      this.fetchGoldPrice()
      this.intervals.goldPriceFetcher = setInterval(() => this.fetchGoldPrice(), 1000 * 60 * 30);
    },
    methods: {
      fetchGoldPrice (showResultMessage) {
        this.$store.dispatch(GOLD_PRICE_FETCH)
          .then(() => {
            if (showResultMessage) {
              const title = 'Kurs emas sudah memakai data terkini!'

              alertHelper.basic(title, null, alertHelper.typeEnums.success)
            } else {
              if (this.isGoldPriceExpired) {
                this.showUpdateGoldPriceNotification()
              }
            }
          })
          .catch((error) => {
            if (showResultMessage) {
              errorHandlerHelper.handleGeneralApiError(error)
            }
          })
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      minimizeSidebar() {
        this.$sidebar.toggleMinimize()
      },
      toggleNavbar() {
        this.showNavbar = !this.showNavbar;
      },
      showUpdateGoldPriceNotification () {
        const action = () => {
          this.$router.push(urlConstant.WEB_URL_REFERENCES_GOLD_PRICES_NEW)
        }

        const content = 'Kurs emas hari ini belum diupdate. Untuk memastikan ketepatan harga rekomendasi produk, mohon segera update kurs emas.'
        
        alertHelper.confirm(null, content, alertHelper.typeEnums.warning, 'Update Kurs Emas', 'Tunda', action)
      }
    },
    computed: {
      goldPrice () {
        const gp = this.$store.getters.getGoldPrice
        return {
          formattedPrice: gp && gp.price ? filterHelper.currencyFormat(gp.price) : '',
          formattedPostedDate: gp && gp.postedDate ? filterHelper.dateFormat(gp.postedDate) : ''
        }
      },
      isGoldPriceExpired () {
        const gp = this.$store.getters.getGoldPrice

        if (!gp || !gp.postedDate) {
          return true
        }

        // Expired date is at the midday on the next day
        const expiredDateTime = moment(gp.postedDate).startOf('day').add(36, 'h')
        const now = moment()

        return now > expiredDateTime
      },
      canUpdateGoldPrice () {
        const userRole = this.$store.getters.getUserRole
        return userRoleEnum.ROLE_GROUP_MANAGER_AND_ABOVE.includes(userRole)
      },
      envTenantName() {
        // eslint-disable-next-line no-undef
        return process.env.VUE_APP_TENANT_NAME
      },
    },
    filters: {
      ...filterHelper.spread
    },
    destroyed () {
      for (const prop in this.intervals) {
        clearInterval(this.intervals[prop])
      }
    }
  }
</script>
<style scoped lang="scss">
  .gold-price-section {
    .gold-price-button {
      cursor: pointer;
    }

    .icon-refresh {
      display: none;
    }
    .icon-normal {
      display: inline;
    }

    &:hover {
      .icon-refresh {
        display: inline;
      }
      .icon-normal {
        display: none;
      }
    }
  }

  .kf.container-fluid {
    padding: 30px 30px 0;
  }

  @media screen and (max-width: 768px) {
    .kf.container-fluid {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
</style>
