import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import goldPrice from './modules/goldPrice'
import domainSettings from './modules/domainSettings'
import createPersistedState from 'vuex-persistedstate'

// eslint-disable-next-line no-undef
const debug = process.env.NODE_ENV !== 'production'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    goldPrice,
    domainSettings,
  },
  plugins: [createPersistedState()],
  strict: debug
})
