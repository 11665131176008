<template>
  <div v-loading="isLoading" element-loading-background="rgba(255, 255, 255, 0.6)">
    <div class="print-content m-auto" v-show="dataLoaded">
      <div class="orientation">
        <div class="section-barcode text-center">
          <barcode v-if="model.barcode" :value="model.barcode" width='2' height='40' :text="model.code" fontSize='19' fontOptions='bold'>
            Barcode gagal
          </barcode>
        </div>
        <div class="section-description p-1">
          <div class="row m-0">
            <p class="col-12 p-0 text-small product-title">{{model.name}}</p>
            <p class="col-6 p-0 text-small">B: {{model.weight}}gr</p>
            <p class="col-6 p-0 text-small">S: {{productSize}}</p>
            <p class="col-6 p-0 text-small">K: {{model.carat}}</p>
            <p class="col-6 p-0 text-small">PK: {{model.caratPercentage}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import _ from 'lodash'
  import VueBarcode from 'vue-barcode'
  import base64url from 'base64url'
  import { Loading } from 'element-ui'
  import * as urlConstant from '../../../constants/urlConstant'

  Vue.use(Loading)

  export default {
    components: {
      'barcode': VueBarcode
    },
    data () {
      return {
        dataLoaded: false,
        isProductLoading: false,
        model: {
          id: null,
          name: '',
          code: '',
          barcode: '',
          weight: null,
          carat: null,
          caratPercentage: null,
          exchangePercentage: null
        }
      }
    },
    created () {
      this.init()
    },
    methods: {
      init () {
        const productId = base64url.decode(this.$route.params.productSlug)

        this.isProductLoading = true
        this.axios.get(`${urlConstant.API_URL_PRODUCTS}/${productId}`)
          .then(response => {
            if (!response.data.isSuccess || !response.data.product) {
              this.isProductLoading = false
              return
            }

            this.model = _.cloneDeep(Object.assign(this.model, response.data.product))

            // Get the barcode value
            if (this.model.code) {
              const codeNumber = this.model.code.slice(-7)
              const codeSegments = this.model.code.split('-')
              const domainCodeSegment = codeSegments && codeSegments.length >= 2
                ? codeSegments[codeSegments.length-2]
                : ''
              const domainCode = domainCodeSegment.length == 1
                ? domainCodeSegment
                : ''
              this.model.barcode = `${domainCode}${codeNumber}`
            }

            this.isProductLoading = false
            this.dataLoaded = true

            this.$nextTick(() => {
              setTimeout(() => {
                this.printWhenDomReady()
              }, 750)
            })
          })
          .catch(() => {
            this.isProductLoading = false
          })
      },
      printWhenDomReady () {
        if (document.readyState === 'complete') {
          window.print()
          return
        }

        window.addEventListener('load', () => {
          window.print()
        })
      },
    },
    computed: {
      isLoading () {
        return this.isProductLoading
      },
      productSize () {
        return this.model.size || '-'
      }
    },
  }
</script>
<style scoped lang="scss">
  .print-content {
    // Paper size: 20mm x 20mm * 2 -> 40mm x 40mm
    width: 40mm; // 20mm * 2
    height: 40mm; // 20mm * 2
    color: black;
    transform-origin: top;
    transform: scale(5.5);

    .orientation {
      transform: rotate(90deg);
    }

    // ----- DEBUG
    // border: 1px solid black;

    .section-barcode {
      width: 100%;
      height: 50%;
      transform: rotate(180deg) scale(0.65) translateX(-35px);

      // ----- DEBUG
      // border-bottom: 1px solid black;
    }

    .section-description {
      width: 100%;
      height: 50%;
      font-weight: bold;
      margin-left: 2px;

      .product-title {
        overflow: hidden;
        max-height: 2.5em;
      }
    }

    .text-small {
      font-size: 14px;
      line-height: 1.2em;
    }

    p {
      margin: 0;
    }
  }
</style>
