<template>
  <div :class="vertical ? (flexLayout ? 'd-flex flex-row vertical-tabs-layout' : 'row') : ''">
    <div :class="vertical ? verticalNavClasses : ''">
      <div class="nav-tabs-navigation" :class="vertical ? `verical-navs ${verticalTabNavClasses}` : ''">
        <div class="nav-tabs-wrapper">
          <slot name="nav"></slot>
        </div>
      </div>
    </div>
    <div :class="vertical ? verticalTabContentClasses : ''">
      <slot name="content"></slot>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'tabs-layout',
    props: {
      vertical: Boolean,
      verticalNavClasses: [String, Object],
      verticalTabContentClasses: [String, Object],
      verticalTabNavClasses: [String, Object],
      flexLayout: Boolean,
    }
  }
</script>
<style scoped lang="scss">
  .vertical-tabs-layout {
    margin-left: -15px;
    margin-right: -15px;
  }
</style>
