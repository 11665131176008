export const SUPERADMIN = 'Superadmin'
export const OWNER = 'Owner'
export const ADMIN = 'Admin'
export const MANAGER = 'Manager'
export const CASHIER = 'Cashier'
export const PRODUCT = 'Product'

export const ROLE_GROUP_ADMIN_AND_ABOVE = [ SUPERADMIN, OWNER, ADMIN ]
export const ROLE_GROUP_MANAGER_AND_ABOVE = [ SUPERADMIN, OWNER, ADMIN, MANAGER ]
export const ROLE_GROUP_CASHIER_AND_ABOVE = [ SUPERADMIN, OWNER, ADMIN, MANAGER, CASHIER ]
export const ROLE_GROUP_PRODUCT_AND_ABOVE = [ SUPERADMIN, OWNER, ADMIN, MANAGER, PRODUCT ]

const locals = {}
locals[SUPERADMIN] = 'Super Admin'
locals[OWNER] = 'Pemilik'
locals[ADMIN] = 'Admin'
locals[MANAGER] = 'Manajer'
locals[CASHIER] = 'Kasir'
locals[PRODUCT] = 'Produk'

export const getLocal = (key) => { return locals[key] ? locals[key] : key }
