<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <nav class="footer-nav">
          <ul>
            <li>
              <!-- <a href="javascript:void(0);" target="_blank" rel="noopener">Kebijakan Privasi</a> -->
              <a href="javascript:void(0);">Kebijakan Privasi</a>
            </li>
            <li>
              <!-- <a href="javascript:void(0);" target="_blank" rel="noopener">Ketentuan Penggunaan</a> -->
              <a href="javascript:void(0);">Ketentuan Penggunaan</a>
            </li>
          </ul>
        </nav>
        <div class="credits ml-auto">
          <div class="copyright">
            &copy; {{envAppName}} {{envCopyrightYear}} | Coded by
            <a href="https://wa.me/6281280060250" target="_blank" rel="noopener">Agni Pradharma</a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
  export default {
    name: 'content-footer',
    computed: {
      envAppName() {
        // eslint-disable-next-line no-undef
        return process.env.VUE_APP_NAME
      },
      envCopyrightYear() {
        // eslint-disable-next-line no-undef
        return process.env.VUE_APP_COPYRIGHT_YEAR
      },
    },
  }
</script>
<style scoped lang="scss">
  .footer {
    padding-top: 5px;
  }
</style>
