import Vue from 'vue'
import VueRouter from 'vue-router'
import VueRouterPrefetch from 'vue-router-prefetch'
import * as userRoleEnum from '../enums/userRoleEnum'
import * as routerConstant from '../constants/routerConstant'
import * as domainSettingsConstant from '../constants/domainSettingsConstant'
import routeGuardHelper from '../helpers/routeGuardHelper'

// Main dashboard
import DashboardLayout from '../components/Dashboard/Layout/DashboardLayout'

// General views
import NotFound from '../components/GeneralViews/NotFoundPage'

// Pages
import Login from '../components/Dashboard/Views/Pages/Login'

// Print
import InvoiceDetailPrint from '../components/Dashboard/Views/InvoiceDetailPrint'
import PurchaseDetailPrint from '../components/Dashboard/Views/PurchaseDetailPrint'
import ProductDetailPrintBarcode from '../components/Dashboard/Views/ProductDetailPrintBarcode'

// Dashboard contents
const Overview = () => import(/* webpackChunkName: "widgets" */ '../components/Dashboard/Views/Dashboard/Overview')
const Purchases = () => import(/* webpackChunkName: "widgets" */ '../components/Dashboard/Views/Purchases')
const PurchaseDetail = () => import(/* webpackChunkName: "widgets" */ '../components/Dashboard/Views/PurchaseDetail')
const Invoices = () => import(/* webpackChunkName: "widgets" */ '../components/Dashboard/Views/Invoices')
const InvoiceDetail = () => import(/* webpackChunkName: "widgets" */ '../components/Dashboard/Views/InvoiceDetail')
const Trays = () => import(/* webpackChunkName: "widgets" */ '../components/Dashboard/Views/Trays')
const TrayDetail = () => import(/* webpackChunkName: "widgets" */ '../components/Dashboard/Views/TrayDetail')
const Expenses = () => import(/* webpackChunkName: "widgets" */ '../components/Dashboard/Views/Expenses')
const Products = () => import(/* webpackChunkName: "widgets" */ '../components/Dashboard/Views/Products')
const ProductDetail = () => import(/* webpackChunkName: "widgets" */ '../components/Dashboard/Views/ProductDetail')
const Reports = () => import(/* webpackChunkName: "widgets" */ '../components/Dashboard/Views/Reports')
const ReportSales = () => import(/* webpackChunkName: "widgets" */ '../components/Dashboard/Views/ReportSales')
const ReportSalesAlt = () => import(/* webpackChunkName: "widgets" */ '../components/Dashboard/Views/ReportSalesAlt')
const ReportSalesAltDetail = () => import(/* webpackChunkName: "widgets" */ '../components/Dashboard/Views/ReportSalesAltDetail')
const ReportSalesAltList = () => import(/* webpackChunkName: "widgets" */ '../components/Dashboard/Views/ReportSalesAltList')
const ReportPurchases = () => import(/* webpackChunkName: "widgets" */ '../components/Dashboard/Views/ReportPurchases')
const ReportProductsStockCategoryMutation = () => import(/* webpackChunkName: "widgets" */ '../components/Dashboard/Views/ReportProductsStockCategoryMutation')
const References = () => import(/* webpackChunkName: "widgets" */ '../components/Dashboard/Views/References')
const GoldPrices = () => import(/* webpackChunkName: "widgets" */ '../components/Dashboard/Views/GoldPrices')
const GoldPriceDetail = () => import(/* webpackChunkName: "widgets" */ '../components/Dashboard/Views/GoldPriceDetail')
const Employees = () => import(/* webpackChunkName: "widgets" */ '../components/Dashboard/Views/Employees')
const EmployeeDetail = () => import(/* webpackChunkName: "widgets" */ '../components/Dashboard/Views/EmployeeDetail')
const Customers = () => import(/* webpackChunkName: "widgets" */ '../components/Dashboard/Views/Customers')
const CustomerDetail = () => import(/* webpackChunkName: "widgets" */ '../components/Dashboard/Views/CustomerDetail')
const Suppliers = () => import(/* webpackChunkName: "widgets" */ '../components/Dashboard/Views/Suppliers')
const SupplierDetail = () => import(/* webpackChunkName: "widgets" */ '../components/Dashboard/Views/SupplierDetail')
const Settings = () => import(/* webpackChunkName: "widgets" */ '../components/Dashboard/Views/Settings')
const Account = () => import(/* webpackChunkName: "widgets" */ '../components/Dashboard/Views/Account')
const ProductTransfers = () => import(/* webpackChunkName: "widgets" */ '../components/Dashboard/Views/ProductTransfers')
const ProductTransferDetail = () => import(/* webpackChunkName: "widgets" */ '../components/Dashboard/Views/ProductTransferDetail')

const createBeforeEnter = (guardFuncts) => {
  return async (to, from, next) => {
    for (let funct of guardFuncts) {
      try {
        await funct()
      } catch (error) {
        return redirect(to, from, next, error.redirectPath);
      }
    }
    next()
  }
}

const redirect = (to, from, next, url) => {
  if (from.path == url) {
    next(new Error(routerConstant.REDIRECT_TO_SELF))
    return
  }
  next(url)
}

const routes = [
  {
    path: '/',
    name: 'root',
    redirect: '/dashboard',
    beforeEnter: createBeforeEnter([
      () => routeGuardHelper.mustAuthenticated()
    ])
  },
  {
    path: '/dashboard',
    component: DashboardLayout,
    beforeEnter: createBeforeEnter([
      () => routeGuardHelper.mustAuthenticated()
    ]),
    children: [
      {
        path: '/',
        name: 'dashboard',
        component: Overview
      }
    ]
  },
  {
    path: '/account',
    component: DashboardLayout,
    beforeEnter: createBeforeEnter([
      () => routeGuardHelper.mustAuthenticated()
    ]),
    children: [
      {
        path: '/',
        name: 'account',
        component: Account
      },
    ]
  },
  {
    path: '/purchases/print/:purchaseSlug',
    name: 'purchases-print-slug',
    component: PurchaseDetailPrint,
    beforeEnter: createBeforeEnter([
      () => routeGuardHelper.mustHaveDomainSettings({ [domainSettingsConstant.IS_WAREHOUSE]: false }),
      () => routeGuardHelper.mustAuthenticatedWithRoles(userRoleEnum.ROLE_GROUP_CASHIER_AND_ABOVE)
    ]),
  },
  {
    path: '/purchases/print',
    name: 'purchases-print',
    redirect: '/purchases',
    beforeEnter: createBeforeEnter([
      () => routeGuardHelper.mustHaveDomainSettings({ [domainSettingsConstant.IS_WAREHOUSE]: false }),
      () => routeGuardHelper.mustAuthenticatedWithRoles(userRoleEnum.ROLE_GROUP_CASHIER_AND_ABOVE)
    ]),
  },
  {
    path: '/purchases',
    component: DashboardLayout,
    beforeEnter: createBeforeEnter([
      () => routeGuardHelper.mustHaveDomainSettings({ [domainSettingsConstant.IS_WAREHOUSE]: false }),
      () => routeGuardHelper.mustAuthenticatedWithRoles(userRoleEnum.ROLE_GROUP_MANAGER_AND_ABOVE)
    ]),
    children: [
      {
        path: '/',
        name: 'purchases',
        component: Purchases
      },
      {
        path: 'new',
        name: 'purchases-new',
        component: PurchaseDetail
      },
      {
        path: 'view',
        name: 'purchases-view',
        redirect: '/purchases',
      },
      {
        path: 'view/:purchaseSlug',
        name: 'purchases-view-slug',
        component: PurchaseDetail
      },
    ]
  },
  {
    path: '/invoices/print/:invoiceSlug',
    name: 'invoices-print-slug',
    component: InvoiceDetailPrint,
    beforeEnter: createBeforeEnter([
      () => routeGuardHelper.mustHaveDomainSettings({ [domainSettingsConstant.IS_WAREHOUSE]: false }),
      () => routeGuardHelper.mustAuthenticatedWithRoles(userRoleEnum.ROLE_GROUP_CASHIER_AND_ABOVE)
    ]),
  },
  {
    path: '/invoices/print',
    name: 'invoices-print',
    redirect: '/invoices',
    beforeEnter: createBeforeEnter([
      () => routeGuardHelper.mustHaveDomainSettings({ [domainSettingsConstant.IS_WAREHOUSE]: false }),
      () => routeGuardHelper.mustAuthenticatedWithRoles(userRoleEnum.ROLE_GROUP_CASHIER_AND_ABOVE)
    ]),
  },
  {
    path: '/invoices',
    component: DashboardLayout,
    beforeEnter: createBeforeEnter([
      () => routeGuardHelper.mustHaveDomainSettings({ [domainSettingsConstant.IS_WAREHOUSE]: false }),
      () => routeGuardHelper.mustAuthenticatedWithRoles(userRoleEnum.ROLE_GROUP_CASHIER_AND_ABOVE)
    ]),
    children: [
      {
        path: '/',
        name: 'invoices',
        component: Invoices
      },
      {
        path: 'new',
        name: 'invoices-new',
        component: InvoiceDetail
      },
      {
        path: 'view',
        name: 'invoices-view',
        redirect: '/invoices',
      },
      {
        path: 'view/:invoiceSlug',
        name: 'invoices-view-slug',
        component: InvoiceDetail
      },
    ]
  },
  {
    path: '/trays',
    component: DashboardLayout,
    beforeEnter: createBeforeEnter([
      () => routeGuardHelper.mustHaveDomainSettings({ [domainSettingsConstant.IS_WAREHOUSE]: false }),
      () => routeGuardHelper.mustAuthenticatedWithRoles(userRoleEnum.ROLE_GROUP_CASHIER_AND_ABOVE)
    ]),
    children: [
      {
        path: '/',
        name: 'trays',
        component: Trays
      },
      {
        path: 'new',
        name: 'trays-new',
        component: TrayDetail
      },
      {
        path: 'view',
        name: 'trays-view',
        redirect: '/trays',
      },
      {
        path: 'view/:traySlug',
        name: 'trays-view-slug',
        component: TrayDetail
      },
      {
        path: 'edit',
        name: 'trays-edit',
        redirect: '/trays',
      },
      {
        path: 'edit/:traySlug',
        name: 'trays-edit-slug',
        component: TrayDetail
      },
    ]
  },
  {
    path: '/expenses',
    component: DashboardLayout,
    beforeEnter: createBeforeEnter([
      () => routeGuardHelper.mustHaveDomainSettings({ [domainSettingsConstant.IS_WAREHOUSE]: false }),
      () => routeGuardHelper.mustAuthenticatedWithRoles(userRoleEnum.ROLE_GROUP_MANAGER_AND_ABOVE)
    ]),
    children: [
      {
        path: '/',
        name: 'expenses',
        component: Expenses
      },
    ]
  },
  {
    path: '/products/print-barcode/:productSlug',
    name: 'products-print-barcode-slug',
    component: ProductDetailPrintBarcode,
    beforeEnter: createBeforeEnter([
      () => routeGuardHelper.mustAuthenticatedWithRoles(userRoleEnum.ROLE_GROUP_PRODUCT_AND_ABOVE)
    ]),
  },
  {
    path: '/products/print-barcode',
    name: 'products-print-barcode',
    redirect: '/products',
    beforeEnter: createBeforeEnter([
      () => routeGuardHelper.mustAuthenticatedWithRoles(userRoleEnum.ROLE_GROUP_PRODUCT_AND_ABOVE)
    ]),
  },
  {
    path: '/products',
    component: DashboardLayout,
    beforeEnter: createBeforeEnter([
      () => routeGuardHelper.mustAuthenticatedWithRoles(userRoleEnum.ROLE_GROUP_PRODUCT_AND_ABOVE)
    ]),
    children: [
      {
        path: '/',
        name: 'products',
        component: Products
      },
      {
        path: 'new',
        name: 'products-new',
        component: ProductDetail
      },
      {
        path: 'view',
        name: 'products-view',
        redirect: '/products',
      },
      {
        path: 'view/:productSlug',
        name: 'products-view-slug',
        component: ProductDetail
      },
      {
        path: 'edit',
        name: 'products-edit',
        redirect: '/products',
      },
      {
        path: 'edit/:productSlug',
        name: 'products-edit-slug',
        component: ProductDetail
      }
    ]
  },
  {
    path: '/customers',
    component: DashboardLayout,
    beforeEnter: createBeforeEnter([
      () => routeGuardHelper.mustHaveDomainSettings({ [domainSettingsConstant.IS_WAREHOUSE]: false }),
      () => routeGuardHelper.mustAuthenticatedWithRoles(userRoleEnum.ROLE_GROUP_CASHIER_AND_ABOVE)
    ]),
    children: [
      {
        path: '/',
        name: 'customers',
        component: Customers
      },
      {
        path: 'new',
        name: 'customers-new',
        component: CustomerDetail
      },
      {
        path: 'view',
        name: 'customers-view',
        redirect: '/customers',
      },
      {
        path: 'view/:customerSlug',
        name: 'customers-view-slug',
        component: CustomerDetail
      },
      {
        path: 'edit',
        name: 'customers-edit',
        redirect: '/customers',
      },
      {
        path: 'edit/:customerSlug',
        name: 'customers-edit-slug',
        component: CustomerDetail
      }
    ]
  },
  {
    path: '/suppliers',
    component: DashboardLayout,
    beforeEnter: createBeforeEnter([
      () => routeGuardHelper.mustHaveDomainSettings({ [domainSettingsConstant.IS_WAREHOUSE]: false }),
      () => routeGuardHelper.mustAuthenticatedWithRoles(userRoleEnum.ROLE_GROUP_CASHIER_AND_ABOVE)
    ]),
    children: [
      {
        path: '/',
        name: 'suppliers',
        component: Suppliers
      },
      {
        path: 'new',
        name: 'suppliers-new',
        component: SupplierDetail
      },
      {
        path: 'view',
        name: 'suppliers-view',
        redirect: '/suppliers',
      },
      {
        path: 'view/:supplierSlug',
        name: 'suppliers-view-slug',
        component: SupplierDetail
      },
      {
        path: 'edit',
        name: 'suppliers-edit',
        redirect: '/suppliers',
      },
      {
        path: 'edit/:supplierSlug',
        name: 'suppliers-edit-slug',
        component: SupplierDetail
      }
    ]
  },
  {
    path: '/reports',
    component: DashboardLayout,
    beforeEnter: createBeforeEnter([
      () => routeGuardHelper.mustHaveDomainSettings({ [domainSettingsConstant.IS_WAREHOUSE]: false }),
      () => routeGuardHelper.mustAuthenticatedWithRoles(userRoleEnum.ROLE_GROUP_MANAGER_AND_ABOVE)
    ]),
    children: [
      {
        path: '/',
        name: 'reports',
        component: Reports
      },
      // Sales
      {
        path: 'sales',
        name: 'reports-sales',
        component: ReportSales
      },
      {
        path: 'sales/alt',
        name: 'reports-sales-alt',
        component: ReportSalesAlt,
        beforeEnter: createBeforeEnter([
          () => routeGuardHelper.mustAuthenticatedWithRoles(userRoleEnum.ROLE_GROUP_ADMIN_AND_ABOVE)
        ]),
      },
      {
        path: 'sales/alt/list',
        name: 'reports-sales-alt-list',
        component: ReportSalesAltList,
        beforeEnter: createBeforeEnter([
          () => routeGuardHelper.mustAuthenticatedWithRoles(userRoleEnum.ROLE_GROUP_ADMIN_AND_ABOVE)
        ]),
      },
      {
        path: 'sales/alt/list/:year/:month',
        name: 'reports-sales-alt-list-year-month',
        component: ReportSalesAltDetail,
        beforeEnter: createBeforeEnter([
          () => routeGuardHelper.mustAuthenticatedWithRoles(userRoleEnum.ROLE_GROUP_ADMIN_AND_ABOVE)
        ]),
      },
      {
        path: 'sales/alt/list/:year',
        name: 'reports-sales-alt-list-year',
        redirect: 'sales/alt/list',
        beforeEnter: createBeforeEnter([
          () => routeGuardHelper.mustAuthenticatedWithRoles(userRoleEnum.ROLE_GROUP_ADMIN_AND_ABOVE)
        ]),
      },
      // Purchases
      {
        path: 'purchases',
        name: 'reports-purchases',
        component: ReportPurchases
      },
      // Products
      {
        path: 'products',
        name: 'reports-products',
        redirect: '/reports',
      },
      {
        path: 'products/stock-category-mutations',
        name: 'reports-products-stock-category-mutations',
        component: ReportProductsStockCategoryMutation
      },
    ]
  },
  {
    path: '/product-transfers',
    component: DashboardLayout,
    beforeEnter: createBeforeEnter([
      () => routeGuardHelper.mustAuthenticatedWithRoles(userRoleEnum.ROLE_GROUP_PRODUCT_AND_ABOVE)
    ]),
    children: [
      {
        path: '/',
        name: 'product-transfers',
        component: ProductTransfers
      },
      {
        path: 'new',
        name: 'product-transfers-new',
        component: ProductTransferDetail
      },
      {
        path: 'view',
        name: 'product-transfers-view',
        redirect: '/product-transfers',
      },
      {
        path: 'view/:productTransferSlug',
        name: 'product-transfers-view-slug',
        component: ProductTransferDetail
      },
    ]
  },
  {
    path: '/references',
    component: DashboardLayout,
    beforeEnter: createBeforeEnter([
      () => routeGuardHelper.mustAuthenticatedWithRoles(userRoleEnum.ROLE_GROUP_MANAGER_AND_ABOVE)
    ]),
    children: [
      {
        path: '/',
        name: 'references',
        component: References
      },
      {
        path: 'gold-prices',
        name: 'references-goldprices',
        component: GoldPrices
      },
      {
        path: 'gold-prices/new',
        name: 'references-goldprices-new',
        component: GoldPriceDetail
      },
    ]
  },
  {
    path: '/employees',
    component: DashboardLayout,
    beforeEnter: createBeforeEnter([
      () => routeGuardHelper.mustAuthenticatedWithRoles(userRoleEnum.ROLE_GROUP_MANAGER_AND_ABOVE)
    ]),
    children: [
      {
        path: '/',
        name: 'employees',
        component: Employees
      },
      {
        path: 'new',
        name: 'employees-new',
        component: EmployeeDetail
      },
      {
        path: 'view',
        name: 'employees-view',
        redirect: '/employees',
      },
      {
        path: 'view/:employeeSlug',
        name: 'employees-view-slug',
        component: EmployeeDetail
      },
      {
        path: 'edit',
        name: 'employees-edit',
        redirect: '/employees',
      },
      {
        path: 'edit/:employeeSlug',
        name: 'employees-edit-slug',
        component: EmployeeDetail
      }
    ]
  },
  {
    path: '/settings',
    component: DashboardLayout,
    beforeEnter: createBeforeEnter([
      () => routeGuardHelper.mustAuthenticatedWithRoles(userRoleEnum.ROLE_GROUP_MANAGER_AND_ABOVE)
    ]),
    children: [
      {
        path: '/',
        name: 'settings',
        component: Settings
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: createBeforeEnter([
      () => routeGuardHelper.mustNotAuthenticated()
    ]),
  },
  { 
    path: '*', 
    name: 'notfound',
    component: NotFound ,
  }
]

Vue.use(VueRouter)
Vue.use(VueRouterPrefetch)

export default new VueRouter({
  routes, // Short for routes: routes
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }

    return { x: 0, y: 0 }
  }
})
