import swal from 'sweetalert2'

export default new function () {
  this.typeEnums = {
    muted: 'muted',
    primary: 'primary',
    info: 'info',
    success: 'success',
    warning: 'warning',
    danger: 'danger',
  }

  const basicAlert = function (title, content, contentHtml, type, confirmButton, onConfirm) {
    swal({
      titleText: title || '',
      text: content || '',
      html: contentHtml || '',
      buttonsStyling: false,
      confirmButtonClass: 'btn btn-primary btn-fill',
      confirmButtonText: confirmButton || 'Ok',
      type: type || this.typeEnums.success,
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then(() => {
      if (onConfirm) {
        onConfirm()
      }
    })
  }
  
  this.basic = function (title, content, type, confirmButton, onConfirm) {
    basicAlert(title, content, null, type, confirmButton, onConfirm)
  }

  this.basicHtml = function (title, contentHtml, type, confirmButton, onConfirm) {
    basicAlert(title, null, contentHtml, type, confirmButton, onConfirm)
  }

  const confirmAlert = function (title, content, contentHtml, type, confirmButton, cancelButton, onConfirm, onCancel) {
    swal({
      titleText: title || '',
      text: content || '',
      html: contentHtml || '',
      buttonsStyling: false,
      type: type || this.typeEnums.warning,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: confirmButton || 'Ok',
      cancelButtonText: cancelButton || 'Batal',
      confirmButtonClass: 'btn btn-primary btn-fill',
      cancelButtonClass: 'btn btn-default btn-fill',
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then(() => {
      if (onConfirm) {
        onConfirm()
      }
    }, (dismiss) => {
      // Dismiss can be 'overlay', 'cancel', 'close', 'esc', 'timer'
      if (dismiss === 'cancel') {
        if (onCancel) {
          onCancel()
        }
      }
    })
  }

  this.confirm = function (title, content, type, confirmButton, cancelButton, onConfirm, onCancel) {
    confirmAlert(title, content, null, type, confirmButton, cancelButton, onConfirm, onCancel)
  }

  this.confirmHtml = function (title, contentHtml, type, confirmButton, cancelButton, onConfirm, onCancel) {
    confirmAlert(title, null, contentHtml, type, confirmButton, cancelButton, onConfirm, onCancel)
  }
}
