import moment from 'moment'
import * as dateTimeConstant from '../constants/dateTimeConstant'

export default new function () {
  this.dateFormat = function (value) {
    if (!value) {
      return ''
    }

    return moment(value).format(dateTimeConstant.MOMENT_DATE_FORMAT)
  }
  
  this.dateTimeFormat = function (value) {
    if (!value) {
      return ''
    }

    return moment(value).format(dateTimeConstant.MOMENT_DATE_TIME_FORMAT)
  }

  this.numberFormat = function (value) {
    if (!value && value !== 0) {
      return ''
    }

    return new Intl.NumberFormat('id-ID', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value)
  }

  this.numberWithFractionFormat = function (value) {
    if (!value && value !== 0) {
      return ''
    }

    return new Intl.NumberFormat('id-ID', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    }).format(value)
  }
  
  this.currencyFormat = function (value) {
    if (!value && value !== 0) {
      return ''
    }

    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value)
  }

  this.highlight = function (text, query) {
    if (!text && text !== 0) {
      return ''
    }

    if (!query && query !== 0) {
      return text
    }

    var regex = new RegExp(query, 'gi');

    return text.toString().replace(regex, function (matchedText) {
      return ('<strong>' + matchedText + '</strong>');
    });
  }

  // Get all of the function to spread
  this.spread = {
    dateFormat: this.dateFormat,
    dateTimeFormat: this.dateTimeFormat,
    numberFormat: this.numberFormat,
    currencyFormat: this.currencyFormat,
    numberWithFractionFormat: this.numberWithFractionFormat,
    highlight: this.highlight,
  }
}
