import store from '../store/store'
import * as urlConstant from '../constants/urlConstant'

export default new function () {
  this.mustHaveDomainSettings = async (settings) => {
    try {
      const domainSettings = await store.getters.getDomainSettings
      for (const key of Object.keys(settings)) {
        if (domainSettings[key] !== settings[key]) {
          throw { redirectPath: urlConstant.WEB_URL_DASHBOARD_HOME }
        }
      }
    } catch {
      throw { redirectPath: urlConstant.WEB_URL_DASHBOARD_HOME }
    }
  }

  this.mustNotAuthenticated = async () => {
    if (!store.getters.isAuthenticated) {
      return
    }
    throw { redirectPath: urlConstant.WEB_URL_DASHBOARD_HOME }
  }

  this.mustAuthenticated = async () => {
    if (store.getters.isAuthenticated) {
      return
    }
    throw { redirectPath: urlConstant.WEB_URL_LOGIN }
  }

  this.mustAuthenticatedWithRoles = async (roles) => {
    if (store.getters.isAuthenticated) {
      if (roles && roles.length && roles.includes(store.getters.getUserRole)) {
        return
      }
      throw { redirectPath: urlConstant.WEB_URL_DASHBOARD_HOME }
    }
    throw { redirectPath: urlConstant.WEB_URL_LOGIN }
  }
}
