import alertHelper from './alertHelper'

export default new function () {
  this.handleGeneralApiError = function (error) {
    // No response
    if (error && !error.response) {
      const content = 'Tidak dapat mengakses server, mohon cek koneksi internet kamu.'
      alertHelper.basic(null, content, alertHelper.typeEnums.warning)
      return
    }

    if (error && error.response && error.response.status) {
      // Unauthorized
      if (error.response.status === 401) {
        const content = 'Sesi login kamu telah selesai, silakan login kembali.'
        alertHelper.basic(null, content, alertHelper.typeEnums.warning)
        return
      }

      // Forbidden
      if (error.response.status === 403) {
        const content = 'Akun kamu tidak mempunyai hak akses atas data ini, silakan login menggunakan akun yang sesuai.'
        alertHelper.basic(null, content, alertHelper.typeEnums.warning)
        return
      }
    }

    // Other unhandled error
    const content = 'Terjadi kesalahan pada sistem, mohon coba beberapa saat lagi.'
    alertHelper.basic(null, content, alertHelper.typeEnums.warning)
  }
}
