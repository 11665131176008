import axios from 'axios'
import VueAxios from 'vue-axios'
import router from '../router/router'
import store from '../store/store'
import { AUTH_CLEAR, REFRESH_TOKEN_FETCH } from '../store/actions/auth'
import { KEY_USER_TOKEN } from '../constants/localStorageKeyConstant'
import * as urlConstant from '../constants/urlConstant'

// Add a request interceptor
axios.interceptors.request.use(config => {
  const token = localStorage.getItem(KEY_USER_TOKEN)

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  
  return config
})

// Add a response interceptor
axios.interceptors.response.use(response => {
  return response
}, async error => {
  if (error && error.response && error.response.status) {
    // Unauthorized
    if (error.response.status === 401) {
      // Check if there are refresh token in progress
      if (!store.getters.isRefreshingToken) {
        try { await store.dispatch(REFRESH_TOKEN_FETCH) }
        catch {
          await store.dispatch(AUTH_CLEAR)

          if (window.location.pathname !== urlConstant.WEB_URL_LOGIN) {
            router.push({ path: urlConstant.WEB_URL_LOGIN, query: { callback: `${window.location.pathname}${window.location.search}` } })
          }

          return Promise.reject(error)
        }
      }
      else {
        while (store.getters.isRefreshingToken) {
          await new Promise(resolve => setTimeout(resolve, 100))
        }
      }

      return axios.request(error.config)
    }
  }
  
  return Promise.reject(error)
})

export default {
  install(Vue) {
    Vue.use(VueAxios, axios)
  }
}
