<template>
  <div class="login-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <form @submit.prevent="validate">
                <card type="login">
                  <h3 slot="header" class="header text-center">Login</h3>

                  <fg-input v-model="model.email" 
                            addon-left-icon="nc-icon nc-email-85"
                            placeholder="Email"
                            name="email"
                            data-vv-name="Email"
                            type="email"
                            required
                            v-validate="'required|email'"
                            :error="getError('Email')"></fg-input>

                  <fg-input v-model="model.password" 
                            addon-left-icon="nc-icon nc-key-25" 
                            placeholder="Password"
                            name="password"
                            data-vv-name="Password"
                            type="password"
                            required
                            v-validate="'required'"
                            :error="getError('Password')"></fg-input>
                  
                  <template slot="footer">
                    <p-button type="warning" 
                              round block class="mb-3"
                              native-type="submit"
                              :disabled="isLoading"
                              @click.prevent="validate">Masuk</p-button>
                    <p class="mb-0 text-center">
                      <a href="javascript:void(0);">Lupa password</a>
                    </p>
                  </template>
                </card>
              </form>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div class="full-page-background" style="background-image: url(/static/img/background/nt-background.jpg) "></div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Card, Button } from '../../../UIComponents'
  import AppNavbar from './Layout/AppNavbar'
  import AppFooter from './Layout/AppFooter'
  import { WEB_URL_DASHBOARD_HOME, API_URL_ACCOUNT_LOGIN } from '../../../../constants/urlConstant'
  import { AUTH_SET } from '../../../../store/actions/auth'
  import errorHandlerHelper from '../../../../helpers/errorHandlerHelper'
  import alertHelper from '../../../../helpers/alertHelper'
  
  export default {
    components: {
      Card,
      AppNavbar,
      AppFooter,
      [Button.name]: Button
    },
    methods: {
      toggleNavbar() {
        document.body.classList.toggle('nav-open')
      },
      closeMenu() {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      },
      getError (fieldName) {
        return this.errors.first(fieldName)
      },
      validate () {
        this.$validator.validateAll().then(isValid => {
          this.$emit('on-submit', this.model, isValid)

          if (!isValid) {
            return
          }

          this.login()
        })
      },
      login() {
        this.isLoading = true

        this.axios.post(API_URL_ACCOUNT_LOGIN, this.model)
          .then(response => {
            if (!response.data.isSuccess) {
              this.showLoginFailedAlert(response.data)
              this.isLoading = false
              return
            }

            this.$store
              .dispatch(AUTH_SET, { 
                user: response.data.user, 
                token: response.data.token,
                refreshDeviceId: response.data.refreshDeviceId,
                refreshToken: response.data.refreshToken, 
              })
              .then(() => {
                if (this.$route.query.callback) {
                  this.$router.push(this.$route.query.callback)
                }
                else {
                  this.$router.push(WEB_URL_DASHBOARD_HOME)
                }
              })

            this.isLoading = false
          })
          .catch(error => {
            errorHandlerHelper.handleGeneralApiError(error)
            this.isLoading = false
          })
      },
      showLoginFailedAlert(apiResponse) {
        if (!apiResponse || apiResponse.apiError) {
          errorHandlerHelper.handleGeneralApiError()
          return
        }

        const content = 'Email atau password kamu salah.'

        alertHelper.basic(null, content, alertHelper.typeEnums.warning)
      }
    },
    data() {
      return {
        isLoading: false,
        model: {
          email: '',
          password: ''
        }
      }
    },
    beforeDestroy() {
      this.closeMenu()
    }
  }
</script>
<style scoped>
  .login-page .card-login .input-group:last-child {
    margin-bottom: 10px;
  }

  .login-page .content .container {
    margin-top: 0;
    margin-bottom: 8vh;
  }

  .full-page .footer {
    position: relative;
  }

  @media screen and (max-width: 374px) {
    .login-page .content {
      padding-top: 21vh;
    }
  }

  @media screen and (min-height: 640px) {
    .full-page .footer {
      position: absolute;
    }
  }
</style>
