import NProgress from 'nprogress'
import store from '../store/store'
import { REDIRECT_TO_SELF } from '../constants/routerConstant'
import { DOMAIN_SETTINGS_FETCH } from '../store/actions/domainSettings'

NProgress.configure({ showSpinner: false })

const progressShowDelay = 100

let routeResolved = false

/**
 * Initializes NProgress after a specified delay only if the route was not resolved yet.
 */
function tryInitProgress() {
  routeResolved = false
  setTimeout(()=> {
    if (!routeResolved) {
      NProgress.start()
    }
  }, progressShowDelay)
}

export default function initProgress(router) {
  router.beforeEach(async (to, from, next) => {
    tryInitProgress()

    // Fetch domain settings before route change
    if (from.path !== to.path) {
      await store.dispatch(DOMAIN_SETTINGS_FETCH)
    }

    next()
  })

  router.afterEach(() => {
    // Complete the animation of the route progress bar.
    routeResolved = true
    NProgress.done()
  })

  router.onError(error => {
    // This is a hack to stop the progressbar if the route is redirecting to itself.
    // The reason is because afterEach hook is not triggered for that case.
    if (error && error.message == REDIRECT_TO_SELF) {
      routeResolved = true
      NProgress.done()
    }
  })
}
