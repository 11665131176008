import * as userRoleEnum from './enums/userRoleEnum'
import * as domainSettingsConstant from './constants/domainSettingsConstant'

export default [
  {
    name: 'Dashboard',
    icon: 'nc-icon nc-bank',
    path: '/dashboard'
  },
  {
    name: 'Laporan',
    icon: 'nc-icon nc-single-copy-04',
    path: '/reports',
    domainSettings: { [domainSettingsConstant.IS_WAREHOUSE]: false },
    roles: userRoleEnum.ROLE_GROUP_MANAGER_AND_ABOVE
  },
  {
    separator: true,
  },
  {
    name: 'Penjualan',
    icon: 'nc-icon nc-money-coins',
    domainSettings: { [domainSettingsConstant.IS_WAREHOUSE]: false },
    roles: userRoleEnum.ROLE_GROUP_CASHIER_AND_ABOVE,
    children: [
      {
        name: 'Transaksi',
        path: '/invoices',
        domainSettings: { [domainSettingsConstant.IS_WAREHOUSE]: false },
        roles: userRoleEnum.ROLE_GROUP_CASHIER_AND_ABOVE
      },
      {
        name: 'Baki',
        path: '/trays',
        domainSettings: { [domainSettingsConstant.IS_WAREHOUSE]: false },
        roles: userRoleEnum.ROLE_GROUP_CASHIER_AND_ABOVE
      }
    ]
  },
  {
    name: 'Pembelian',
    icon: 'nc-icon nc-credit-card',
    path: '/purchases',
    domainSettings: { [domainSettingsConstant.IS_WAREHOUSE]: false },
    roles: userRoleEnum.ROLE_GROUP_MANAGER_AND_ABOVE
  },
  {
    name: 'Biaya',
    icon: 'nc-icon nc-paper',
    path: '/expenses',
    domainSettings: { [domainSettingsConstant.IS_WAREHOUSE]: false },
    roles: userRoleEnum.ROLE_GROUP_MANAGER_AND_ABOVE
  },
  {
    separator: true,
  },
  {
    name: 'Transfer',
    icon: 'nc-icon nc-delivery-fast',
    roles: userRoleEnum.ROLE_GROUP_PRODUCT_AND_ABOVE,
    children: [
      {
        name: 'Produk',
        path: '/product-transfers',
        roles: userRoleEnum.ROLE_GROUP_PRODUCT_AND_ABOVE
      },
    ]
  },
  {
    separator: true,
  },
  {
    name: 'Produk',
    icon: 'nc-icon nc-tag-content',
    path: '/products',
    roles: userRoleEnum.ROLE_GROUP_PRODUCT_AND_ABOVE
  },
  {
    name: 'Pelanggan',
    icon: 'nc-icon nc-single-02',
    path: '/customers',
    domainSettings: { [domainSettingsConstant.IS_WAREHOUSE]: false },
    roles: userRoleEnum.ROLE_GROUP_CASHIER_AND_ABOVE
  },
  {
    name: 'Vendor',
    icon: 'nc-icon nc-delivery-fast',
    path: '/suppliers',
    domainSettings: { [domainSettingsConstant.IS_WAREHOUSE]: false },
    roles: userRoleEnum.ROLE_GROUP_CASHIER_AND_ABOVE
  },
  {
    separator: true,
  },
  {
    name: 'Data Referensi',
    icon: 'nc-icon nc-bullet-list-67',
    path: '/references',
    roles: userRoleEnum.ROLE_GROUP_MANAGER_AND_ABOVE
  },
  {
    name: 'Karyawan',
    icon: 'nc-icon nc-badge',
    path: '/employees',
    roles: userRoleEnum.ROLE_GROUP_MANAGER_AND_ABOVE
  },
  {
    name: 'Pengaturan',
    icon: 'nc-icon nc-settings-gear-65',
    path: '/settings',
    roles: userRoleEnum.ROLE_GROUP_MANAGER_AND_ABOVE
  },
]
