<template>
  <div class="card card-stats" :class="cardClass">
    <div class="card-body">
      <div class="row">
        <div class="col-5 col-md-4">
          <slot name="header">
            <div class="icon-big text-center"
                 :class="`text-${type}`">
              <i :class="icon"></i>
            </div>
          </slot>
        </div>
        <div class="col-7 col-md-8 pl-0 d-flex flex-column justify-content-center">
          <slot name="content">
            <div class="numbers">
              <p v-if="smallTitleResizeOptions" v-resize-text="smallTitleResizeOptions" class="card-category">{{smallTitle}}</p>
              <p v-else class="card-category">{{smallTitle}}</p>
              <p v-if="titleResizeOptions" v-resize-text="titleResizeOptions" class="card-title" :class="titleClass">{{title}}</p>
              <p v-else class="card-title" :class="titleClass">{{title}}</p>
            </div>
          </slot>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <hr v-if="$slots.footer" />
      <slot name="footer"></slot>
    </div>

  </div>
</template>
<script>
  import ResizeText from 'vue-resize-text'

  export default {
    name: 'stats-card',
    directives: {
      ResizeText
    },
    props:{
      type: {
        type: String,
        description: 'Card text type (primary|info|danger|default|warning|success)'
      },
      title: {
        type: String,
        description: 'Card title'
      },
      titleClass: {
        type: String,
        description: 'Card title style class'
      },
      smallTitle: {
        type: String,
        description: 'Card small title'
      },
      icon: {
        type: String,
        description: 'Card icon'
      },
      cardClass: {
        type: String,
        description: 'Card style class'
      },
      smallTitleResizeOptions: {
        type: Object,
      },
      titleResizeOptions: {
        type: Object,
      },
    }
  }

</script>
<style scoped>
  .card-content {
    min-height: 85px
  }
</style>
