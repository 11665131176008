<template>
  <div class="form-group"
       :class="[
       {'input-group': hasIcon},
       {'has-danger': error},
       {'input-group-focus': focused},
       {'has-label': label || $slots.label},
       {'has-success': hasSuccess},
       {'disabled': disabled}]">
    <slot name="label">
      <label v-if="label" :class="labelClasses">
        {{label}}<span v-if="required">*</span>
      </label>
    </slot>
    <div class="mb-0" :class="{ 'input-group': hasIcon }">
      <div v-if="addonLeftIcon || $slots.addonLeft" class="input-group-prepend">
          <span class="input-group-text">
            <slot name="addonLeft">
              <i :class="addonLeftIcon"></i>
            </slot>
          </span>
      </div>
      <slot>
        <currency-input v-if="kind === kinds.money" 
                        :currency="null"
                        :precision="0"
                        locale="id"
                        :value="value"
                        v-on="listeners"
                        v-bind="$attrs"
                        class="form-control"
                        :required="required"
                        :disabled="disabled"
                        :class="[{'is-valid': hasSuccess}, inputClasses, {'is-invalid': error}]"
                        aria-describedby="addon-right addon-left"/>
        <input v-else
              :value="value"
              v-on="listeners"
              v-bind="$attrs"
              class="form-control"
              :required="required"
              :disabled="disabled"
              :class="[{'is-valid': hasSuccess}, inputClasses, {'is-invalid': error}]"
              aria-describedby="addon-right addon-left">
      </slot>
      <div v-if="addonRightIcon || $slots.addonRight" class="input-group-append">
        <span class="input-group-text">
          <slot name="addonRight">
            <i :class="addonRightIcon"></i>
          </slot>
        </span>
      </div>
    </div>
    <slot name="infoBlock"></slot>
    <slot name="helpBlock">
      <div class="invalid-feedback error-text" :class="{'mt-1': hasIcon}" v-if="error && !hideErrorMessage">
        {{ error }}
      </div>
    </slot>
  </div>
</template>
<script>
  import Vue from 'vue'
  import VueCurrencyInput from 'vue-currency-input'

  Vue.use(VueCurrencyInput)

  export default {
    inheritAttrs: false,
    name: 'fg-input',
    props: {
      required: {
        type: Boolean,
        description: 'Whether input is required (adds an asterix *)'
      },
      label: {
        type: String,
        description: 'Input label (text before input)'
      },
      error: {
        type: String,
        description: 'Input error (below input)'
      },
      hideErrorMessage: {
        type: Boolean,
        description: 'Hide the error message display'
      },
      labelClasses: {
        type: String,
        description: 'Input label css classes'
      },
      inputClasses: {
        type: String,
        description: 'Input css classes'
      },
      value: {
        type: [String, Number],
        description: 'Input value'
      },
      addonRightIcon: {
        type: String,
        description: 'Addon right icon'
      },
      addonLeftIcon: {
        type: String,
        description: 'Addont left icon'
      },
      kind: {
        type: String,
        description: 'The input kind: [default, money]'
      },
      disabled: {
        type: Boolean,
        description: 'Whether input is disabled'
      },
    },
    data() {
      return {
        touched: false,
        focused: false,
        hadError: false,
        kinds: {
          money: 'money',
          default: 'default'
        }
      }
    },
    computed: {
      listeners() {
        return {
          ...this.$listeners,
          input: this.updateValue,
          focus: this.onFocus,
          blur: this.onBlur
        }
      },
      hasSuccess() {
        return this.hadError && this.touched && !this.error
      },
      hasIcon() {
        const { addonRight, addonLeft } = this.$slots
        return addonRight !== undefined || addonLeft !== undefined || this.addonRightIcon !== undefined || this.addonLeftIcon !== undefined
      }
    },
    methods: {
      updateValue(evt) {
        let value = this.kind === this.kinds.money ? evt : evt.target.value
        if (!this.touched && value) {
          this.touched = true
        }
        this.$emit('input', value)
      },
      onFocus(value) {
        this.focused = true;
        this.$emit('focus', value);
      },
      onBlur(value) {
        this.focused = false;
        this.$emit('blur', value);
      }
    },
    created() {
      this.$watch('error', (newVal) => {
        if(newVal){
          this.hadError = true;
        }
      }, {immediate: true})
    }
  }
</script>
<style scoped>
.error-text {
  display: block;
}
</style>
