var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.layoutComponent,{tag:"component",attrs:{"vertical-nav-classes":_vm.verticalNavClasses,"vertical-tab-content-classes":_vm.verticalTabContentClasses,"vertical-tab-nav-classes":_vm.verticalTabNavClasses,"vertical":_vm.vertical,"flex-layout":_vm.flexLayout}},[_c('template',{slot:"nav"},[_c('ul',{staticClass:"nav",class:[_vm.type ? `nav-pills-${_vm.type}`: '',
            _vm.pills ? 'nav-pills': 'nav-tabs',
           {'nav-pills-icons': _vm.icons},
           {'flex-column nav-stacked': _vm.vertical},
           {'justify-content-center': _vm.centered},
           _vm.tabNavClasses
          ],attrs:{"role":"tablist"}},_vm._l((_vm.tabs),function(tab){return _c('li',{key:tab.id || tab.title,staticClass:"nav-item",class:_vm.tabNavItemClasses},[_c('a',{staticClass:"nav-link",class:{active: tab.active},attrs:{"data-toggle":"tab","role":"tab","href":"javascript:void(0);","aria-selected":tab.active},on:{"click":function($event){$event.preventDefault();return _vm.activateTab(tab)}}},[_c('tab-item-content',{attrs:{"tab":tab}})],1)])}),0)]),_c('div',{staticClass:"tab-content",class:[_vm.tabContentClasses],attrs:{"slot":"content"},slot:"content"},[_vm._t("default")],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }