export const OPEN = 'Open'
export const CLOSED = 'Closed'
export const PAID = 'Paid'
export const VOIDED = 'Voided'

const locals = {}
locals[OPEN] = 'Utang'
locals[CLOSED] = 'Batal'
locals[PAID] = 'Lunas'
locals[VOIDED] = 'Pembayaran Batal'

export const getLocal = (key) => { return locals[key] ? locals[key] : key }
