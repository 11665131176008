import { AUTH_SET, AUTH_CLEAR, REFRESH_TOKEN_FETCH, REFRESH_TOKEN_SET, REFRESH_TOKEN_STATE_REFRESHING_SET } from '../actions/auth'
import { KEY_USER_TOKEN, KEY_USER_REFRESH_TOKEN, KEY_USER_REFRESH_DEVICE_ID } from '../../constants/localStorageKeyConstant'
import * as urlConstant from '../../constants/urlConstant'

const state = {
  refreshDeviceId: localStorage.getItem(KEY_USER_REFRESH_DEVICE_ID) || '',
  refreshToken: localStorage.getItem(KEY_USER_REFRESH_TOKEN) || '',
  isRefreshingToken: false,
  token: localStorage.getItem(KEY_USER_TOKEN) || '',
  user: {}
}

const getters = {
  isAuthenticated: state => !!state.token,
  getUser: state => state.user,
  getUserRole: state => state.user.role,
  isRefreshingToken: state => state.isRefreshingToken
}

const actions = {
  [AUTH_SET]: ({commit}, {user, token, refreshDeviceId, refreshToken}) => {
    return new Promise((resolve) => {
      commit(AUTH_SET, {user, token, refreshDeviceId, refreshToken})
      commit(REFRESH_TOKEN_STATE_REFRESHING_SET, false)
      resolve()
    })
  },
  [AUTH_CLEAR]: ({commit}) => {
    return new Promise((resolve) => {
      commit(AUTH_CLEAR)
      commit(REFRESH_TOKEN_STATE_REFRESHING_SET, false)
      resolve()
    })
  },
  [REFRESH_TOKEN_FETCH]: ({commit, state}) => {
    return new Promise((resolve, reject) => {
      commit(REFRESH_TOKEN_STATE_REFRESHING_SET, true)
      
      // Use fetch so it won't trigger another refresh token from axios interceptor if it's failed
      fetch(urlConstant.API_URL_ACCOUNT_REFRESH_TOKEN, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          token: state.token, 
          refreshToken: state.refreshToken, 
          refreshDeviceId: state.refreshDeviceId 
        }),
      })
        .then(response => response.json())
        .then(data => {
          if (!data.isSuccess) {
            reject()
            return
          }

          commit(REFRESH_TOKEN_SET, {
            token: data.token, 
            refreshToken: data.refreshToken, 
            refreshDeviceId: data.refreshDeviceId
          })
          commit(REFRESH_TOKEN_STATE_REFRESHING_SET, false)
          
          resolve()
        })
        .catch((error) => {
          commit(REFRESH_TOKEN_STATE_REFRESHING_SET, false)

          reject(error)
        })
    })
  }
}

const mutations = {
  [AUTH_SET]: (state, {user, token, refreshDeviceId, refreshToken}) => {
    state.user = user
    state.token = token
    state.refreshDeviceId = refreshDeviceId
    state.refreshToken = refreshToken

    localStorage.setItem(KEY_USER_TOKEN, token)
    localStorage.setItem(KEY_USER_REFRESH_DEVICE_ID, refreshDeviceId)
    localStorage.setItem(KEY_USER_REFRESH_TOKEN, refreshToken)
  },
  [AUTH_CLEAR]: (state) => {
    state.user = {}
    state.token = ''
    state.refreshDeviceId = ''
    state.refreshToken = ''

    localStorage.removeItem(KEY_USER_TOKEN)
    localStorage.removeItem(KEY_USER_REFRESH_DEVICE_ID)
    localStorage.removeItem(KEY_USER_REFRESH_TOKEN)
  },
  [REFRESH_TOKEN_STATE_REFRESHING_SET]: (state, isRefreshingToken) => {
    state.isRefreshingToken = isRefreshingToken
  },
  [REFRESH_TOKEN_SET]: (state, {token, refreshToken, refreshDeviceId}) => {
    state.token = token
    state.refreshDeviceId = refreshDeviceId
    state.refreshToken = refreshToken

    localStorage.setItem(KEY_USER_TOKEN, token)
    localStorage.setItem(KEY_USER_REFRESH_DEVICE_ID, refreshDeviceId)
    localStorage.setItem(KEY_USER_REFRESH_TOKEN, refreshToken)
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
