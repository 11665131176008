<template>
  <div class="user">
    <div class="photo">
      <img src="/static/img/default-avatar-female.png" alt="user avatar"/>
    </div>
    <div class="info">
      <a data-toggle="collapse" :aria-expanded="!isClosed" @click="toggleMenu" href="javascript:void(0);">
         <span>
           {{ model.user.fullName }}
           <b class="caret"></b>
        </span>
      </a>
      <div class="clearfix"></div>
      <div>
        <collapse-transition>
          <ul class="nav nav-menu" v-show="!isClosed">
            <li>
              <a href="javascript:void(0);" @click.prevent="myAccount">
                <span class="sidebar-mini-icon"><i class="nc-icon nc-circle-10"></i></span>
                <span class="sidebar-normal">Akun</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0);" @click.prevent="logout">
                <span class="sidebar-mini-icon"><i class="nc-icon nc-button-power"></i></span>
                <span class="sidebar-normal">Logout</span>
              </a>
            </li>
          </ul>
        </collapse-transition>
      </div>
    </div>
  </div>
</template>
<script>
  import { CollapseTransition } from 'vue2-transitions'
  import { AUTH_CLEAR } from '../../../store/actions/auth'
  import * as urlConstant from '../../../constants/urlConstant'

  export default {
    components: {
      CollapseTransition
    },
    data() {
      return {
        isClosed: true,
        model: {
          user: {}
        }
      }
    },
    created() {
      this.init()
    },
    methods: {
      toggleMenu() {
        this.isClosed = !this.isClosed
      },
      init() {
        this.model.user = this.$store.getters.getUser
      },
      logout() {
        this.$store
          .dispatch(AUTH_CLEAR)
          .then(() => {
            this.$router.push(urlConstant.WEB_URL_LOGIN)
          })
      },
      myAccount() {
        this.$router.push(urlConstant.WEB_URL_ACCOUNT)
      }
    }
  }
</script>
<style scoped lang="scss">
  .nav.nav-menu {
    margin-top: 0;

    .sidebar-mini-icon {
      i {
        width: 34px !important;
      }
    }
  }
</style>
