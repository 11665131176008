import axios from 'axios'
import { GOLD_PRICE_FETCH, GOLD_PRICE_SET } from '../actions/goldPrice'
import * as urlConstant from '../../constants/urlConstant'

const state = {
  goldPrice: {}
}

const getters = {
  hasGoldPrice: state => !!state.goldPrice.price,
  getGoldPrice: state => state.goldPrice,
}

const actions = {
  [GOLD_PRICE_FETCH]: ({commit}) => {
    return new Promise((resolve, reject) => {
      axios.get(urlConstant.API_URL_GOLD_PRICES_LATEST)
        .then(response => {
          if (!response.data.isSuccess) {
            reject()
            return
          }

          const goldPrice = response.data.goldPrice
          
          commit(GOLD_PRICE_SET, goldPrice)
          resolve(goldPrice)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

const mutations = {
  [GOLD_PRICE_SET]: (state, goldPrice) => {
    state.goldPrice = goldPrice || {}
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
