export const CASH = 'Cash'
export const BANK_TRANSFER = 'BankTransfer'
export const CREDIT_CARD = 'CreditCard'

const locals = {}
locals[CASH] = 'Tunai'
locals[BANK_TRANSFER] = 'Transfer Bank'
locals[CREDIT_CARD] = 'Kartu Kredit'

export const getLocal = (key) => { return locals[key] ? locals[key] : key }
export const getDefault = () => { return CASH; }
