<template>
  <div class="sidebar"
       :data-color="backgroundColor"
       :data-active-color="activeColor">
    <div class="logo">
      <router-link class="simple-text logo-mini"
         aria-label="sidebar mini logo"
         to="/dashboard">
          <div class="logo-img">
              <img :src="logo" alt="App Logo">
          </div>
      </router-link>
      <router-link class="simple-text logo-normal" to="/dashboard">
        {{ title }}
      </router-link>
    </div>
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <slot></slot>
      <ul class="nav">
        <slot name="links">
          <sidebar-item v-for="(link, linkIndex) in filteredSidebarLinks"
                        :key="link.name || `link-${linkIndex}`"
                        :link="link">
            <sidebar-item v-for="(subLink, subLinkIndex) in link.children"
                          :key="subLink.name || `sublink-${linkIndex}-${subLinkIndex}`"
                          :link="subLink">
            </sidebar-item>
          </sidebar-item>
        </slot>
      </ul>
    </div>
  </div>
</template>
<script>
  import 'perfect-scrollbar/dist/css/perfect-scrollbar.css'

  export default {
    props: {
      title: {
        type: String,
        // eslint-disable-next-line no-undef
        default: process.env.VUE_APP_TENANT_NAME_SHORT,
        description: 'Sidebar title'
      },
      backgroundColor: {
        type: String,
        default: 'black',
        validator: (value) => {
          let acceptedValues = ['white', 'brown', 'black']
          return acceptedValues.indexOf(value) !== -1
        },
        description: 'Sidebar background color (white|brown|black)'
      },
      activeColor: {
        type: String,
        default: 'success',
        validator: (value) => {
          let acceptedValues = ['primary', 'info', 'success', 'warning', 'danger']
          return acceptedValues.indexOf(value) !== -1
        },
        description: 'Sidebar active text color (primary|info|success|warning|danger)'
      },
      logo: {
        type: String,
        default: '/static/img/app-logo.png',
        description: 'Sidebar Logo'
      },
      sidebarLinks: {
        type: Array,
        default: () => [],
        description: 'Sidebar links. Can be also provided as children components (sidebar-item)'
      },
      autoClose: {
        type: Boolean,
        default: true
      }
    },
    provide() {
      return {
        autoClose: this.autoClose
      }
    },
    methods: {
      async initScrollBarAsync () {
        let isWindows = navigator.platform.startsWith('Win');
        if(!isWindows) {
          return;
        }
        const PerfectScroll = await import('perfect-scrollbar')
        PerfectScroll.initialize(this.$refs.sidebarScrollArea)
      }
    },
    computed: {
      filteredSidebarLinks () {
        let links = this.sidebarLinks

        // Domain settings filter
        const domainSettings = this.$store.getters.getDomainSettings
        links = links.filter(sl => {
          if (!sl.domainSettings) {
            return true
          }
          for (const key of Object.keys(sl.domainSettings)) {
            if (domainSettings[key] !== sl.domainSettings[key]) {
              return false
            }
          }
          return true
        })

        // Roles filter
        const userRole = this.$store.getters.getUserRole
        links = links.filter(sl => 
          !sl.roles || 
          !sl.roles.length ||
          sl.roles.includes(userRole))

        // Remove double separator
        links = links.reduce((arr, sl) => {
          if (!arr.length ||
              arr[arr.length - 1].separator !== true ||
              sl.separator !== true
          ) {
            arr.push(sl)
            return arr
          }
          return arr
        }, [])

        return links
      }
    },
    mounted () {
      this.initScrollBarAsync()
    },
    beforeDestroy () {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.showSidebar = false
      }
    }
  }
</script>
<style>
  @media (min-width: 992px) {
    .navbar-search-form-mobile,
    .nav-mobile-menu {
      display: none;
    }
  }
</style>
