import Vue from 'vue'
import './pollyfills'
import Vuex from 'vuex'
import VueNotify from 'vue-notifyjs'
import VeeValidate, { Validator } from 'vee-validate'
import veeValidateLang from 'vee-validate/dist/locale/id'
import elementUiLang from './constants/locale/element-ui/id'
import locale from 'element-ui/lib/locale'
import App from './App.vue'

// Plugins
import GlobalComponents from './globalComponents'
import GlobalDirectives from './globalDirectives'
import SideBar from './components/UIComponents/SidebarPlugin'
import axiosPlugin from './plugins/axiosPlugin'
import initProgress from './plugins/nProgressPlugin'

// Router setup
import router from './router/router'

// Library imports
import './assets/sass/paper-dashboard.scss'
import './assets/sass/demo.scss'

import sidebarLinks from './sidebarLinks'
import './registerServiceWorker'

// Store setup
import store from './store/store'

// Plugin setup
Vue.use(Vuex)
Vue.use(GlobalDirectives)
Vue.use(GlobalComponents)
Vue.use(VueNotify)
Vue.use(SideBar, {sidebarLinks: sidebarLinks})
Vue.use(axiosPlugin)
Vue.use(VeeValidate, {
  // The default 'fields' bag name clashes with export csv module
  fieldsBagName: 'vvFields'
})

// Localization
Validator.localize('id', veeValidateLang)
locale.use(elementUiLang)

// Progress bar
initProgress(router)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store
})
