import axios from 'axios'
import { DOMAIN_SETTINGS_FETCH, DOMAIN_SETTINGS_SET } from '../actions/domainSettings'
import * as urlConstant from '../../constants/urlConstant'

const state = {
  domainSettings: {}
}

const getters = {
  getDomainSettings: state => state.domainSettings,
}

const actions = {
  [DOMAIN_SETTINGS_FETCH]: ({commit}) => {
    return new Promise((resolve, reject) => {
      axios.get(urlConstant.API_URL_DOMAIN_SETTINGS)
        .then(response => {
          if (!response.data.isSuccess) {
            reject()
            return
          }

          const domainSettings = response.data.domainSettings
          
          commit(DOMAIN_SETTINGS_SET, domainSettings)
          resolve(domainSettings)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

const mutations = {
  [DOMAIN_SETTINGS_SET]: (state, domainSettings) => {
    state.domainSettings = domainSettings || {}
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
